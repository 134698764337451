<template>
  <div class="round-data-content">
    <div>
      <span class="round-data-intro-text">
        {{ $t("round_data_intro") }}
      </span>
      <div class="round-data-form">
        <div>
          <span class="round-data-form-label">
            {{ $t("select_company") }}*
          </span>
          <v-autocomplete
            data-test="Round:RoundData:SelectCompany"
            :items="roundsAllCompanies"
            item-text="Name"
            item-value="CompanyId"
            color="primary"
            dense
            class="rounds-filters-auto-complete"
            :label="$t('select')"
            outlined
            v-model="roundCompanySelected"
            @change="companyChanged"
            :disabled="roundCompanyFieldDisabled"
            :loading="roundAllCompaniesLoading"
          >
            <template v-slot:progress>
              <v-progress-circular
                indeterminate
                style="position: absolute; right: 40px; top: 10px"
                size="20"
                color="primary"
              ></v-progress-circular>
            </template>
          </v-autocomplete>
        </div>
        <div class="target">
          <span class="round-data-form-label"
            >{{ $t("pre_deal_select") }}
          </span>
          <span class="round-data-form-subtitle">{{
            $t("select_if_pre_deal")
          }}</span>
          <v-checkbox
            data-test="Round:RoundData:PreDeal"
            class="rounds-filters-auto-complete"
            v-model="roundPreDeal"
            color="primary"
            dense
            :label="$t('select_label_if_pre_deal')"
          ></v-checkbox>
        </div>
        <div>
          <span class="round-data-form-label"
            >{{ $t("select_investment_vehicle") }}*
          </span>
          <span class="round-data-form-subtitle">{{
            $t("mark_all_vehicles")
          }}</span>
          <v-autocomplete
            data-test="Round:RoundData:SelectVehicle"
            :items="companyInvestmentVehicles"
            item-text="name"
            item-value="id"
            color="primary"
            dense
            class="rounds-filters-auto-complete"
            :label="$t('select_label')"
            outlined
            multiple
            v-model="roundVehiclesSelected"
            @change="saveCompanyInvestmentVehicles"
            :item-disabled="vehicleItemDisabled"
            :loading="companyInvestmentVehiclesLoading"
            :disabled="roundPreDeal"
          >
            <template v-slot:progress>
              <v-progress-circular
                indeterminate
                style="position: absolute; right: 40px; top: 10px"
                size="20"
                color="primary"
              ></v-progress-circular>
            </template>
          </v-autocomplete>
        </div>
        <div>
          <span class="round-data-form-label flex gap-3">
            {{ $t("select_manager_company") }}*

            <v-tooltip bottom color="gray">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-info"
                    class="icon-tooltip-manager"
                  />
                </div>
              </template>
              <span>{{ $t("write_min_3_caracteres") }}</span>
            </v-tooltip>
          </span>
          <v-autocomplete
            data-test="Round:RoundData:ManagerPartner"
            :items="managerPartnerList"
            @keyup="managerPartnerChanged"
            item-text="name"
            item-value="id"
            color="primary"
            dense
            class="rounds-filters-auto-complete"
            :label="$t('select_label')"
            outlined
            v-model="selectedManagerPartner"
            @change="saveManagerPartnerId"
            @blur="handleManagerPartnerBlur"
            :loading="managerPartnerListLoading"
          >
            <template v-slot:progress>
              <v-progress-circular
                indeterminate
                style="position: absolute; right: 40px; top: 10px"
                size="20"
                color="primary"
              ></v-progress-circular>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{
                    managerPartnerFallback
                      ? $t("enter_manager_company")
                      : $t("not_found")
                  }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </div>

        <div class="investment-target-container">
          <div class="currency">
            <span class="round-data-form-label"> {{ $t("currency") }}</span>
            <v-select
              v-model="roundInvestmentCurrency"
              :items="currencyOptions"
              item-text="text"
              item-value="value"
              outlined
              dense
              class="rounds-filters-auto-complete"
              color="primary"
              @change="changePortfolioSizeCurrency"
            ></v-select>
          </div>
          <div class="target">
            <span class="round-data-form-label">
              {{ $t("investment_target") }}
            </span>
            <money
              color="primary"
              dense
              outlined
              thousands="."
              decimal=","
              min="1"
              class="field rounds-filters-auto-complete"
              v-model="roundInvestmentTarget"
              data-test="Round:RoundData:InvestmentTarget"
            >
            </money>
          </div>
        </div>

        <div class="round-date-row">
          <div class="round-date-container">
            <span class="round-date-title">{{ $t("startDate") }}</span>
            <span class="round-date-subtitle"
              >{{ $t("can_be_changed_later") }}
            </span>
            <v-menu
              ref="startMenuDateRef"
              v-model="roundCaptureStartMenuDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  data-test="Round:RoundData:RoundCaptureStartDate"
                  dense
                  class="rounds-filters-date"
                  outlined
                  style="width: 100%"
                  v-model="roundCaptureStartDateText"
                  v-mask="['##/##/####']"
                  append-icon="mdi-calendar"
                  @blur="roundStartDate = roundCaptureStartDateText"
                  v-bind="attrs"
                  v-on="on"
                  label="DD/MM/AAAA"
                  height="34"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-BR"
                color="primary"
                v-model="roundCaptureStartDate"
                min="1850-01-01"
                @change="saveRoundCaptureStartDate"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="round-date-container">
            <span class="round-date-title">{{ $t("endDate") }}</span>
            <span class="round-date-subtitle"
              >{{ $t("can_be_changed_later") }}
            </span>
            <v-menu
              ref="endMenuDateRef"
              :close-on-content-click="false"
              v-model="roundCaptureEndMenuDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  data-test="Round:RoundData:RoundCaptureEndDate"
                  dense
                  class="rounds-filters-date"
                  outlined
                  style="width: 100%"
                  v-model="roundCaptureFinishDateText"
                  v-mask="['##/##/####']"
                  append-icon="mdi-calendar"
                  @blur="roundEndDate = roundCaptureFinishDateText"
                  v-bind="attrs"
                  v-on="on"
                  label="DD/MM/AAAA"
                  height="34"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-BR"
                color="primary"
                v-model="roundCaptureFinishDate"
                min="1850-01-01"
                @change="saveRoundCaptureFinishDate"
              ></v-date-picker>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import { mapMutations, mapState, mapGetters } from "vuex";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { parseStringDate } from "@/shared/helpers/dateHelper.js";
import { roundStatusEnums } from "../RoundStatusEnums.js";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";

export default {
  name: "RoundDataTab",
  props: {
    round: Object,
  },
  data() {
    return {
      roundStatusEnum: roundStatusEnums,
      apiService: new ApiService(),
      roundsAllCompanies: [],
      companyInvestmentVehicles: [],
      finishDate: null,
      roundCaptureStartDate: null,
      roundCaptureStartDateText: null,
      roundCaptureStartMenuDate: false,
      roundCaptureFinishDate: null,
      roundCaptureFinishDateText: null,
      roundCaptureEndMenuDate: false,
      roundVehiclesSelected: [],
      roundInvestmentTarget: null,
      CurrencyEnum,
      roundInvestmentCurrency: CurrencyEnum.BRL,
      roundCompanyFieldDisabled: false,
      roundVehichleFieldDisabled: false,
      roundCopyVehicleToDisableField: null,
      roundPreDeal: false,
      companyInvestmentVehiclesLoading: false,
      roundAllCompaniesLoading: false,
      managerPartnerList: [],
      managerPartnerListLoading: false,
      selectedManagerPartner: null,
      filterName: null,
      managerPartnerFallback: true,
      managerPartnerDefault: null,
      currencyOptions: [
        {
          text: "R$",
          value: CurrencyEnum.BRL,
        },
        {
          text: "US$",
          value: CurrencyEnum.USD,
        },
      ],
    };
  },
  watch: {
    round() {
      if (this.round.id && this.$route.params.roundId) {
        // filtra a lista de empresas com a empresa que está vindo da api
        this.roundCompanySelected = this.round.companyId;
        // Faz a requisição de veículos da empresa, a API só retorna os veículos que tem na empresa
        this.getCompanyVehicles(this.round.companyId);
        // preenche no v-model as empresas que vieram selecionadas no vuex
        this.checkSelectedVehicles();

        this.checkRoundTarget();

        this.roundCaptureStartDateText = format(
          parseStringDate(this.round.captureStartDate),
          "dd/MM/yyyy",
          { locale: ptBR }
        );

        if (this.round.managerPartnerId) {
          this.managerPartnerById(this.round.managerPartnerId);
        }

        this.roundCaptureFinishDateText = format(
          parseStringDate(this.round.captureFinishDate),
          "dd/MM/yyyy",
          { locale: ptBR }
        );

        this.roundInvestmentCurrency = this.round.investmentCurrency;
        this.roundPreDeal = this.round.preDeal;
        // Verifica os campos que não podem ser mais editáveis
        this.checkAvailableFields();
      }
    },
    roundInvestmentTarget() {
      this.setRoundData({
        ...this.round,
        investmentTarget: this.roundInvestmentTarget,
      });
    },
    roundInvestmentCurrency() {
      this.setRoundData({
        ...this.round,
        investmentCurrency: this.roundInvestmentCurrency,
      });
    },
    roundPreDeal() {
      this.setRoundData({
        ...this.round,
        preDeal: this.roundPreDeal,
      });
    },
  },
  async created() {
    await this.getAllCompanies();
    await this.getDefaultManagerPartner();
    this.checkBackButton();
  },
  computed: {
    ...mapState(["round"]),
    ...mapGetters({ currentRoundId: "get_current_round_id" }),
  },
  methods: {
    checkBackButton() {
      window.addEventListener("popstate", (event) => {
        if (this.currentRoundId && !this.$route.params.roundId) {
          this.$router.push(`/round/${this.currentRoundId}`);
        }
      });
    },
    checkAvailableFields() {
      this.roundCompanyFieldDisabled =
        this.round.roundStatus === roundStatusEnums.PUBLISHED;

      if (!this.roundCopyVehicleToDisableField) {
        this.roundCopyVehicleToDisableField = [
          ...this.round.investmentVehicleInvestmentRounds,
        ];
      }
    },
    vehicleItemDisabled(item) {
      if (this.$route.params.roundId) {
        const vehicleSelected = this.roundCopyVehicleToDisableField.find(
          (vehicle) => vehicle.investmentVehicleId === item.id
        );

        if (vehicleSelected && vehicleSelected.hasAllocations) {
          return true;
        }

        return false;
      }
    },
    checkRoundTarget() {
      this.roundInvestmentTarget = this.round.investmentTarget;
    },
    ...mapMutations({
      setRoundData: "SET_ROUND_DATA",
    }),
    checkSelectedVehicles() {
      this.round.investmentVehicleInvestmentRounds.map((vehicle) => {
        // Faz um some pra verifica se já adicionou esse veiculo alguma vez
        if (
          !this.roundVehiclesSelected.some(
            (vehicleId) => vehicleId === vehicle.investmentVehicleId
          )
        )
          this.roundVehiclesSelected.push(vehicle.investmentVehicleId);
      });
    },
    saveManagerPartnerId(id) {
      this.setRoundData({
        ...this.round,
        managerPartnerId: id,
      });
    },
    async getDefaultManagerPartner() {
      this.managerPartnerListLoading = true;

      await this.apiService
        .getRequest(`managerpartner/default`)
        .then((resp) => {
          const defaultManagerPartner = resp.content;

          // se o default partner existir, esse cara já é populado nas opções e no v-model, para já entrar marcado.
          // só popula com o default, quando não tiver manager salvo.
          if (this.round && this.round.managerPartnerId) {
            this.managerPartnerListLoading = false;
            return;
          }

          if (defaultManagerPartner) {
            this.managerPartnerList = [
              {
                id: defaultManagerPartner.id,
                name: `${defaultManagerPartner.name} (${this.$t("default")})`,
              },
            ];

            this.selectedManagerPartner = defaultManagerPartner.id;
            this.saveManagerPartnerId(defaultManagerPartner.id);
          }
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });

      this.managerPartnerListLoading = false;
    },
    async managerPartnerById(id) {
      await this.apiService
        .getRequest(`managerpartner/${id}`)
        .then((resp) => {
          this.managerPartnerList.push(resp.content);

          this.selectedManagerPartner = id;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });
    },
    async managerPartnerChanged(e) {
      const filterName = e.target.value;

      if (filterName && filterName.length >= 3) {
        await this.getManagerPartnerList(filterName);
      }

      if (filterName && filterName.length < 3) {
        this.managerPartnerFallback = true;
      } else {
        this.managerPartnerFallback = false;
      }
    },
    async getManagerPartnerList(filterName) {
      this.managerPartnerListLoading = true;

      await this.apiService
        .getRequest(`managerpartner?nameFilter=${filterName}`)
        .then((resp) => {
          // pegando o managerDefault e colocando a label default no nome (facilitando a vida do admin )
          const filterManagerPartnerOptions = resp.content.map((manager) => {
            let managerPartner = manager;

            if (managerPartner.default) {
              managerPartner.name = `${managerPartner.name} (${this.$t(
                "default"
              )})`;
            }

            return managerPartner;
          });

          this.managerPartnerList = filterManagerPartnerOptions;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_occoured"));
        });

      this.managerPartnerListLoading = false;
    },
    async getAllCompanies() {
      this.roundAllCompaniesLoading = true;
      await this.apiService
        .getRequest("company/list")
        .then((result) => {
          this.roundsAllCompanies = JSON.parse(result.message);
        })
        .catch((err) => {
          this.$toast.error(err.body.message);
        });
      this.roundAllCompaniesLoading = false;
    },
    async getCompanyVehicles(companyId) {
      this.companyInvestmentVehiclesLoading = true;
      await this.apiService
        .getRequest(`investmentvehicle/company-vehicles/${companyId}`)
        .then((result) => {
          this.companyInvestmentVehicles = result.content;
        })
        .catch((err) => {
          this.$toast.error(err.body.message);
        });
      this.companyInvestmentVehiclesLoading = false;
    },
    // Se estiver editando e colocar uma empresa diferente, limpar dados do closing
    // limpar também a lista de veículos da empresa
    checkCompanyToEdit() {
      if (this.$route.params.roundId) {
        if (this.roundCompanySelected !== this.round.companyId) {
          this.setRoundData({
            ...this.round,
            investmentVehicleInvestmentRounds: [],
          });
          this.roundVehiclesSelected = [];
        }
      }
    },
    async companyChanged(e) {
      this.checkCompanyToEdit();
      this.setRoundData({ ...this.round, companyId: e });
      await this.getCompanyVehicles(e);
    },
    formatInputDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    saveRoundCaptureStartDate(date) {
      this.roundCaptureStartDateText = this.formatInputDate(
        this.roundCaptureStartDate
      );
      this.$refs.startMenuDateRef.save(this.roundCaptureStartDateText);
      this.setRoundData({
        ...this.round,
        captureStartDate: date,
      });
    },
    saveRoundCaptureFinishDate(date) {
      this.roundCaptureFinishDateText = this.formatInputDate(
        this.roundCaptureFinishDate
      );
      this.$refs.endMenuDateRef.save(this.roundCaptureFinishDateText);
      this.setRoundData({
        ...this.round,
        CaptureFinishDate: date,
      });
    },
    saveCompanyInvestmentVehicles(e) {
      let savedVehicles = [];
      let vehiclesList = [];

      if (this.roundPreDeal) {
        this.setRoundData({
          ...this.round,
        });
        return;
      }

      if (
        this.round.investmentVehicleInvestmentRounds &&
        this.round.investmentVehicleInvestmentRounds.length
      ) {
        savedVehicles = this.round.investmentVehicleInvestmentRounds.filter(
          (vehicle) => vehicle.stockTypeId || vehicle.vehicleName
        );
      }

      e.forEach((id) => {
        let selectedVehicle = savedVehicles.find(
          (item) => item.investmentVehicleId === id
        );

        if (!selectedVehicle) {
          const vehicleToCompanySelected = this.companyInvestmentVehicles.find(
            (vehicle) => vehicle.id === id
          );
          vehiclesList.push({
            investmentVehicleId: id,
            closingType: vehicleToCompanySelected.closingType,
            vehicleNationality: vehicleToCompanySelected.vehicleNationality,
          });
        } else {
          vehiclesList.push(selectedVehicle);
        }
      });

      this.setRoundData({
        ...this.round,
        investmentVehicleInvestmentRounds: vehiclesList,
      });
    },
  },
};
</script>

<style scoped>
.round-data-content {
  padding: 20px;
}

.round-data-intro-text {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-data-form {
  width: 50%;
}

.round-data-form-label {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
  margin-top: 30px;
}

.round-data-form-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
  margin-top: 8px;
}

.rounds-filters-auto-complete {
  margin-top: 8px;
}

.round-date-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.round-date-container {
  display: flex;
  flex-direction: column;
}

.round-date-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 8px;
}

.round-date-subtitle {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 8px;
}

.field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
  border-style: none;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  height: 40px;
  margin-top: 8px;
}

.flex {
  display: flex;
  align-items: center;
}

.gap-5 {
  gap: 20px;
}

.gap-3 {
  gap: 12px;
}

.icon-tooltip-manager {
  color: var(--primary);
}

.investment-target-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.investment-target-container div.currency {
  width: 20%;
}

.investment-target-container div.target {
  width: 80%;
}
</style>
